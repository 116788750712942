.header-wrapper {
  height: 60px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-logo {
  width: 120px;
  max-width: 100%;
  padding: 16px;
}
