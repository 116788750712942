@import "../../../css/global";

.body-header-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: auto 936px 450px auto;
  grid-template-areas: ". banner logo .";
  overflow: hidden;
  background-color: $color__lightblue;

  @media(max-width: 1375px) {
    grid-template-columns: 1fr 450px;
    grid-template-areas: "banner logo";
  }

  @media(max-width: 1025px) {
    margin-bottom: 0;
    grid-template-columns: auto;
    grid-template-areas: "banner"
                          "logo";
  }

  .body-header-banner {
    position: relative;
    grid-area: banner;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      z-index: 0;
      min-height: 357px;

      @media(max-width: 700px) {
        width: 100%;
        min-height: 200px;
      }

      @media(max-width: 525px) {
        width: auto;
        max-height: 200px;
      }
    }
  }

  .body-header-logo {
    grid-area: logo;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 37px;
    background-color: $color__white;

    @media(max-width: 1025px) {
      align-items: center;
      padding: 20px;
    }

    h1 {
      font-size: 2em;

      @media(max-width: 1025px) {
        text-align: center;
      }
    }

    img {
      width: 80%;
      max-width: 310px;
    }
  }
}
