/* Custom HRC colors */
$color__slate: #242C32;
$color__slate--100: #537187;
$color__slate--300: #323A40;
$color__dark-blue: #11212D;

/* Colors */
$color__red: #f85359;
$color__lightred: #ffe0e0;
$color__green: #00b085;
$color__green--transparent: rgba(0, 176, 133, 0.8);
$color__yellow: #f4b322;
$color__lightyellow: #ffd35c;
$color__blue: #001a3c;
$color__lightblue: #0073e0;
$color__lightblue--transparent: rgba(0, 115, 224, 0.75);

$color__white: #fff;
$color__gray--100: #f8f9fb;
$color__gray--200: #F7F8F8; // light
$color__gray--300: #e5e5e5; // medium
$color__gray--300--transparent: rgba(215, 215, 215, 0.5);
$color__gray--400: #bcbec0; // darker
$color__gray--500: #959497; // dark
$color__gray--500--transparent: rgba(115, 115, 115, .4);
$color__gray--600: #4a4a4a; // darkest
$color__gray--600--transparent: rgba(34,34,34,0.3);
$color__gray--700: #363739; // darkest
$color__gray--800: #231f20; // darkest
$color__black: #000;
$color__black--transparent: rgba(0, 0, 0, .5);


@font-face {
  font-family: "Untitled-Sans-Bold";
  src:url('../fonts/UntitledSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: "Untitled-Sans-Bold-Italic";
  src:url('../fonts/UntitledSans-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Untitled-Sans-Light";
  src:url('../fonts/UntitledSans-Light.ttf') format('truetype');
}
@font-face {
  font-family: "Untitled-Sans-Light-Italic";
  src:url('../fonts/UntitledSans-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Untitled-Sans-Medium";
  src:url('../fonts/UntitledSans-Medium.ttf') format('truetype');
}
@font-face {
  font-family: "Untitled-Sans-Medium-Italic";
  src:url('../fonts/UntitledSans-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Untitled-Sans-Regular";
  src:url('../fonts/UntitledSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: "Untitled-Sans-Regular-Italic";
  src:url('../fonts/UntitledSans-RegularItalic.ttf') format('truetype');
}

* {
  font-family: 'Untitled-Sans-Regular';
  font-size: 14px;
  line-height: 1.2;
}

/* SMARTPHONES*/
@mixin tablet {
  @media only screen and (min-width: 200px) and (max-width: 999px){
    @content
  }
};

/* TABLET LANDSCAPE / DESKTOP */
@mixin desktop {
  @media only screen and (min-width: 1000px) {
    @content
  }
};
