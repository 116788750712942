@import "../../../css/global";

.progressNav-wrapper {
  color: $color__gray--300;
  background: $color__slate--300;
}

.progressNav-wrapper {
  font-size: 12px;
  height: 100%;
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  @media(max-width: 700px) {
    font-size: 10px;
    height: 90px;
    width: 100%;
    flex-direction: row;
  }
}
.progressNav-ends {
  width: auto;
  text-align: center;
  padding: 20px 10px;

  @media(min-width: 701px) {
    height: auto;
    width: 70%;
    margin-top: 10px;
    padding: 10px 0px;
  }

  @media(max-width: 400px) {
    display: none;
  }
}
.progressNav-header {
  border-right: 1px solid #999;

  @media(min-width: 701px) {
    border: none;
    border-bottom: 1px solid #999;
  }
}
.progressNav-footer {
  border-left: 1px solid #999;

  @media(min-width: 701px) {
    border: none;
    border-top: 1px solid #999;
  }
}

.progressNav-body {
  color: $color__gray--600;
  margin: 0 10px;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @media(min-width: 701px) {
    flex-direction: column;
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px 0px;

    svg {
      margin: 5px 0px;

      @media(max-width: 700px) {
        height: 25px;
      }
    }

    p {
      text-align: center;
    }
  }
}

.line-in-middle {
  margin: 0 10px;
  width:100%;
  height:40px;
  background: linear-gradient(to bottom,
    transparent 0%,
    transparent calc(50% - 0.81px),
    $color__gray--500--transparent calc(50% - 0.8px),
    $color__gray--500--transparent calc(50% + 0.8px),
    transparent calc(50% + 0.81px),
    transparent 100%);

  @media(min-width: 701px) {
    margin: 10px 0;
    background: linear-gradient(to right,
      transparent 0%,
      transparent calc(50% - 0.81px),
      $color__gray--500--transparent calc(50% - 0.8px),
      $color__gray--500--transparent calc(50% + 0.8px),
      transparent calc(50% + 0.81px),
      transparent 100%);
  }
}
