@import '../../../css/global.scss';

.Task-footer {
  height: 65px;
  width: calc(100% - 50px);
  padding: 10px 25px;
  background: $color__white;
  border-top: .5px solid $color__gray--300;
  display: grid;
  grid-template-columns: auto 136px;
  grid-template-areas: "helpText button";
  grid-column-gap: 25px;

  @media(max-width: 650px) {
    grid-template-rows: auto auto;
    grid-template-areas:
      "helpText helpText"
      ". button";
  }

  .nextButton {
    grid-area: button;
    color: $color__white;
    cursor: pointer;
    border-radius: 5px;
    background: $color__slate--100;
    padding: 18px;
    text-align: center;
    font-size: 15px;
  }

  .help-text {
    grid-area: helpText;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color__lightblue;
  }
}
