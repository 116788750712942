@import "../../../css/global";

.modal {
  color:$color__black;

  .modal-body {
    // width: 50VW;
    background-color: $color__white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .modal-header {
      background-color: $color__blue;
      padding: 15px 30px;
      color: $color__white;
    }

    .modal-content {
      padding: 20px;

      .modal-message {
        margin-bottom: 20px;
        font-weight: bold;
      }

      .modal-buttons {
        padding: 10px;
        display: flex;
        justify-content: space-around;

        button {
          color: $color__white;
          cursor: pointer;
          border-radius: 5px;
          background: $color__slate--100;
          padding: 18px;
          text-align: center;
          font-size: 15px;
        }

        button:disabled {
          background-color: $color__slate--300;
        }
      }
    }
  }

  .modal-backdrop {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: $color__black--transparent;
  }
}
