@import "../../css/global";

.login-wrapper{

  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $color__gray--300--transparent;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $color__gray--300--transparent;
    opacity:  1;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $color__gray--300--transparent;
    opacity:  1;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color__gray--300--transparent;
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $color__gray--300--transparent;
  }

  height: 100vh;
  width: 100vw;
  color: $color__white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('./../../img/login-regular.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 1000px $color__lightblue--transparent;

  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;

    .login-logo{
      margin: 10px;
      width: 200px;
    }
    .login-title{
      font-size: 15px;
    }
  }

  .login-body {
    max-width: 80vw;
    display: flex;
    justify-content: center;
    padding: 20px 0;

    img {
      margin-right: 10px;
      height: 65px;
      width: 65px;
    }

    @media(max-width: 700px) {
      flex-direction: column;
      align-items: center;

      img {
        margin: 10px 0;
      }
    }

    .input-align {
      max-width: 90%;
      display: flex;
      justify-content: center;

      input {
        color: $color__white;
        outline: none;
        border: none;
        width: 400px;
        height: 65px;
        font-size: 24px;
        padding-left: 20px;
        background: $color__gray--300--transparent;
        box-shadow: 0px 2px 1px $color__gray--600--transparent;

      }

      .go {
        color: $color__white;
        background-color: $color__green--transparent;
        cursor: pointer;
        padding: 24px 13px 19px;
        border-radius: 0 5px 5px 0;
        text-align: center;
        font-size: 20px;
        transition: all 0.5s;
        box-shadow: 0px 2px 1px $color__gray--600--transparent;

        &:hover span {
          padding-right: 15px;
          &:after {
            opacity: 1;
            right: 0;
          }
        }
        &:active{
          transition: 0.1s;
        }

        span {
          display: inline-block;
          position: relative;
          transition: 0.5s;
          &:after {
            content: '»';
            font-size: 20px;
            position: absolute;
            opacity: 0;
            top: 0;
            right: -15px;
            transition: .5s;
          }
        }
      }
    }

  }

  .login-info {
    text-align: center;
    color: $color__gray--300--transparent;
    padding: 10px;
  }
}
