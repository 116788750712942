.onboard-portal {
  width: 100vw;
  height: 100vh;
  display: grid;
  display: flex;
  flex-direction: column;
}

.onboard-portal-body {
  flex: 1;
  width: 100vw;
  display: flex;
  flex-direction: row;

  @media(max-width: 700px) {
    flex-direction: column;
  }
}
