@import "../../../css/global";

.alert-wrapper {
  max-height: calc(100vh - 77px);
  width: 200px;
  padding: 10px;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.alert {
  width: 100%;
  color: $color__white;
  margin-top: 10px;
  opacity: 0.90;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 10px 12px 15px;
  animation: fade 2s;
  -moz-animation: fade 2s;
  -webkit-animation: fade 2s;
  -o-animation: fade 2s;
}
@keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 0.9 }
}
@-moz-keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 0.9 }
}
@-webkit-keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 0.9 }
}
@-o-keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 0.9 }
}

.error {
  background-color: $color__red;
}
.success {
  background-color: $color__green;
}
.warning {
  background-color: $color__yellow;
}
.info {
  background-color: $color__lightblue;
}
.alert-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.alert-type {
  font-size: 15px;
}
.alert-close {
  height: 19px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
