.modal-address-display {
  margin-bottom: 20px;

  .modal-address--type {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .formComponent {
    margin: 0 10px;
  }
}
