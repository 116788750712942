@import "../../../css/global";

.formComponent {
  margin: 20px 20px 0 0;

  label {
    display: block;
    margin-bottom: 5px;
  }

  input,
  select {
    border-radius: 5px;
    height: 30px;
    width: 200px;
    outline: none;
    border: 1px solid $color__gray--500;
    padding-left: 5px;
  }
  select {
    height: 34px;
  }

  .invalid {
    font-size: 12px;
    margin: 3px 1px;
    color: $color__red;
  }

  .width200 {
    width: 200px;
  }

  .checkbox-wrapper {
    position: relative;
    width: 27px;

    .checkbox[class], .checkbox[class]:checked {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 3px;
      border: 1px solid $color__gray--400;
      cursor: pointer;
      display: inline-block;
    }

    .checkbox:disabled, .checkbox:disabled:checked {
      background-color: $color__gray--300;
    }

    #checkbox-check {
      margin: 0;
      width: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }
}
