@import '../../../css/global.scss';

.tasks-wrapper {
  height: 100%;
  width: 100%;
  background: $color__gray--100;

  @media (max-width: 700px) {
    width: 100vw;
  }
}

.Task {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 50px auto;

  @media (max-width: 700px) {
    grid-template-columns: 100vw;
  }

  .Task-header {
    div:nth-child(1) {
      background: $color__blue;
      padding: 15px 30px;
      h1 {
        width: 100%;
        color: $color__white;
        font-size: 20px;

        @media (max-width: 400px) {
          font-size: 18px;
        }
      }
    }
    div:nth-child(2) {
      background: $color__white;
      height: 100px;
      width: 100%;
      border-bottom: 2px solid $color__lightblue;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        margin: 10px;
        color: $color__lightblue;
        text-decoration: underline;
        font-size: 18px;
      }
    }
  }
  .Task-body {
    height: 100%;
    width: 100%;
    background: $color__gray--100;
  }
}

.onboarding-wrapper {
  height: 100%;
  width: 100%;

  iframe {
    height: 100%;
    width: 100%;
  }

  .wotc-info-wrapper {
    display: flex;
    justify-content: center;

    .wotc-info {
      padding: 10px;
      width: 80%;

      @media (min-width: 1326px) {
        width: 50%;
      }
    }
  }

  .wotc {
    height: 100%;
    min-height: calc(100% - 236px);

    @media (min-width: 851px) {
      height: calc(100% - 236px);
    }
  }
}

.verify-task {
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto 1fr auto 2fr;
  grid-template-areas:
    'banner'
    '.'
    'welcome'
    '.'
    'inputs';

  .verify-welcome {
    grid-area: welcome;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h1,
    h2,
    h3 {
      width: 80%;
      margin: 5px;
      text-align: center;
    }
    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 18px;
    }
  }

  .verify-input {
    grid-area: inputs;

    section {
      margin: 0 auto;
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 20px;

      div {
        width: 200px;

        @media (max-width: 700px) {
          width: 300px;
        }

        input {
          background: none;
          border: none;
          outline: none;
        }
      }
    }

    .verify-email {
      @media (min-width: 701px) {
        width: 455px;
      }
    }
  }

  .verify-button {
    grid-area: button;
    display: block;
    margin: 5px auto 20px;
    div {
      cursor: pointer;
      width: 100px;
      background: $color__lightblue;
      color: $color__white;
      text-align: center;
      padding: 15px;
      border-radius: 5px;
    }
  }
}

.complete-task-wrapper {
  width: 100%;

  .complete-task {
    width: 80%;
    max-width: 1286px;
    margin: 0 auto;
    color: $color__gray--600;

    h1 {
      font-size: 22px;
      margin: 10px;
      margin-top: 0;
    }
    h3 {
      font-size: 17px;
      margin: 20px 20px 0 20px;
    }

    h1,
    h3,
    b {
      color: $color__black;
      font-weight: bold;
    }
    > p {
      white-space: pre-wrap;
    }
    > p,
    ul {
      margin: 4px;
      font-size: 15px;
      width: 80%;
      padding-left: 40px;
    }

    > div {
      p {
        padding-left: 15px;
      }
    }

    a {
      color: $color__blue;
      text-decoration: underline;
    }

    .small-text {
      font-size: 12px;
    }

    &__video[class] {
      color: $color__white;
      background: $color__blue;
      padding: 8px;
      text-decoration: none;
      border-radius: 5px;
      margin: 10px 0;
      display: inline-block;
    }

    &__recommend {
      color: $color__lightblue;
    }

    &__systemerror {
      color: $color__red;
      margin-bottom: 24px;
      padding: 16px;
      border: 1px solid $color__red;
      background: $color__lightred;
      border-radius: 4px;
    }
  }
}

.Form-wrapper {
  height: calc(100vh - 114px);
  color: $color__gray--700;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;

  @media (max-width: 700px) {
    height: calc(100vh - 195px);
  }

  section {
    padding: 30px 0;
    width: 90%;
    h1 {
      font-size: 18px;
      height: 20px;
      border-bottom: 1px solid $color__gray--400;
    }

    input {
      max-width: 100%;
    }
  }

  .file {
    padding-top: 20px;
    display: grid;
    grid-template:
      'upload'
      'instructions';

    @media (min-width: 1100px) {
      grid-template: 'upload instructions';
      grid-template-columns: 50% 50%;
    }

    .file-instructions {
      grid-area: 'instructions';
    }

    .file-upload {
      grid-area: 'upload';
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 200px;

      .image {
        height: 200px;
        width: 200px;
        outline: 1px solid $color__gray--400;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        img {
          height: 100%;
        }
      }

      input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }

      .file-select-button {
        color: $color__white;
        cursor: pointer;
        border-radius: 5px;
        background: $color__slate--100;
        padding: 18px;
        text-align: center;
        font-size: 15px;

        @media (max-width: 400px) {
          font-size: 12px;
        }
      }
    }
  }
}

.Tracker-body {
  min-height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: minmax(calc(100% - 85px), auto) auto;

  iframe {
    overflow: scroll;
  }
}

.victig-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .victig-container {
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $color__black;
    text-align: center;
  }

  .victig-button {
    color: $color__white;
    cursor: pointer;
    border-radius: 5px;
    background: $color__slate--100;
    padding: 18px;
    margin: 25px;
    text-align: center;
    font-size: 15px;
  }
}
