@import "../../css/global";

.unsubscribe-wrapper{

  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $color__gray--300--transparent;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $color__gray--300--transparent;
    opacity:  1;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $color__gray--300--transparent;
    opacity:  1;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color__gray--300--transparent;
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $color__gray--300--transparent;
  }

  height: 100vh;
  color: $color__white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('./../../img/login-regular.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px $color__lightblue--transparent;

  >div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 150px 50px 25px 50px;
    padding: 25px;
    .unsubscribe-logo{
      margin: 10px;
      width: 200px;
    }
    .unsubscribe-title{
      font-size: 15px;
    }
  }

  .unsubscribe-text-wrapper {
    height: 200px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
}

.unsubscribe-button {
  display: block;
  color: $color__white;
  background-color: $color__green--transparent;
  cursor: pointer;
  padding: 22px 13px 21px;
  border-radius: 5px;
  text-align: center;
  font-size: 20px;
  transition: all 0.5s;
  box-shadow: 0px 2px 1px $color__gray--600--transparent;
}
